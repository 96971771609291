import React, { useState } from "react";
import { Link } from "react-router-dom";

const TourIncludeExclude = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);

  const paymentMethods = [
    "assets/images/icon/payment-method1.png",
    "assets/images/icon/payment-method2.png",
    "assets/images/icon/payment-method3.png",
    "assets/images/icon/payment-method4.png",
    "assets/images/icon/payment-method6.png",
    "assets/images/icon/payment-method7.png",
    "assets/images/icon/payment-method8.png",
    "assets/images/icon/payment-method10.png",
    "assets/images/icon/payment-method12.png",
    "assets/images/icon/payment-method13.png",
    "assets/images/icon/payment-method15.png",
    "assets/images/icon/payment-method16.png",
  ];

  const handlePaymentMethodClick = (index) => {
    setSelectedPaymentMethod(index);
  };

  return (
    <div>
      <div className="tour-include-exclude m-0 mb-30 radius-6">
        <div className="includ-exclude-point">
          <h4 className="title">Included</h4>
          <ul className="expect-list">
            <li className="list">All Entry Tickets of Hopping Destinations</li>
            <li className="list">Lunch Platter</li>
            <li className="list">Evening Snacks</li>
            <li className="list">First Aid Kit (In case of emergency)</li>
          </ul>
        </div>
        <div className="divider"></div>
        <div className="includ-exclude-point">
          <h4 className="title">Exclude</h4>
          <ul className="expect-list">
            <li className="list">Personal expenses</li>
            <li className="list">
              Anything else that isn't mentioned on Inclusions
            </li>
            <li className="list">Additional Service</li>
            <li className="list">Others and etc</li>
          </ul>
        </div>
      </div>

      <div className="donation-payment">
        <div className="card-style box-shadow border-0">
          <form action="#">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-form mb-24">
                  <label className="contact-label">Name</label>
                  <input
                    className="form-control contact-input"
                    type="text"
                    placeholder="e.g Alex"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-form mb-24">
                  <label className="contact-label">Email</label>
                  <input
                    className="form-control contact-input"
                    type="email"
                    placeholder="e.g example@gmail.com"
                  />
                </div>
              </div>
            </div>

            <div className="form-group col-12 payment-gateway-wrapper mt-10">
              <ul className="payment-gateway-list mb-10">
                {paymentMethods &&
                  paymentMethods.map((method, index) => (
                    <li
                      key={index}
                      className={
                        selectedPaymentMethod === index
                          ? "single-gateway-item selected"
                          : "single-gateway-item"
                      }
                      onClick={() => handlePaymentMethodClick(index)}
                    >
                      <img src={method} alt="img" />
                    </li>
                  ))}
              </ul>
              <div className="remember-me terms-condition">
                <label>
                  <input
                    className="checkbox-style"
                    type="checkbox"
                    value="remember"
                    name="remember"
                  />
                  <small>
                    I agree to all the
                    <Link to="/TermsCondition">
                      <span className="text-primary">Terms</span>
                    </Link>{" "}
                    and{" "}
                    <Link to="/Privacy">
                      <span className="text-primary">Privacy policy</span>
                    </Link>
                  </small>
                  <span className="checkmark-style"></span>
                </label>
              </div>
              <Link to="/Payment" className="btn-primary-submit">
                Payment Now
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TourIncludeExclude;
