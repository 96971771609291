import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import TravelCard from "./TravelCard";
import TourContent from "./TourContent";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import Footer from "../../../Components/Footer/Footer";
import { useApi } from "../../../Context/ApiProvider";
import { useParams } from "react-router-dom";
import { Image_URL } from "../../../Common/BaseURL";
import Header from "../../../Components/Header/Header";

const TourDetail = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { packageId } = useParams();
  const [tour, setTour] = useState(null);
  const [loading, setLoading] = useState(true);
  const swiperRef = useRef(null);
  const reviewsRef = useRef(null); // Create a ref for the reviews section
  const api = useApi();

  useEffect(() => {
    const fetchTourDetail = async () => {
      try {
        const response = await api.get(
          `Package/PackageDetail?name=${packageId}`
        ); // Update API URL

        setTour(response.body.data[0]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tour details:", error);
      }
    };

    const handleFetchData = () => {
      fetchTourDetail();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api, packageId]); // include api if it is subject to change

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update(); // Update Swiper when new slides are added
    }
  }, [tour]);

  // Calculate the average rating
  const averageRating = tour?.totalRating / tour?.reviewCount || 0;

  // Generate an array of stars (full, half, or empty)
  const generateStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (rating >= i) {
        stars.push('full');
      } else if (rating >= i - 0.5) {
        stars.push('half');
      } else {
        stars.push('empty');
      }
    }
    return stars;
  };

  // Get the array of star classes based on the average rating
  const stars = generateStars(averageRating);

  const scrollToReviews = () => {
    if (reviewsRef.current) {
      reviewsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs
        heading="Tour Details"
        link={`/TourDetail/${packageId}`}
        DeLink="/TourList"
        detailHeading="Tour List"
      />

      <section className="tour-details-section section-padding2">
        <div className="tour-details-area">
          <div className="tour-details-banner">
            <div className="swiper tourSwiper-active">
              <Swiper
                loop={true}
                centeredSlides={true}
                slidesPerView={1}
                spaceBetween={24}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                speed={600}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                breakpoints={{
                  991: {
                    slidesPerView: 2,
                  },
                }}
                modules={[Navigation, Autoplay]}
                onInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
              >
                {tour?.packageFiles.map((file, index) => {
                  const fileExtension = file.fileName.split('.').pop().toLowerCase();

                  return (
                    <SwiperSlide key={index} style={{ padding: "0px" }}>
                      {fileExtension === 'mp4' || fileExtension === 'webm' ? (
                        <video
                          src={`${Image_URL}/Package/${file.fileName}`}
                          alt={tour.packageName}
                          controlsF
                        />
                      ) : (
                        <img
                          src={`${Image_URL}/Package/${file.fileName}`}
                          alt={tour.packageName}
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <div className="swiper-button-next">
                <i className="ri-arrow-right-s-line"></i>
              </div>
              <div className="swiper-button-prev">
                <i className="ri-arrow-left-s-line"></i>
              </div>
            </div>
          </div>

          <div className="tour-details-container">
            <div className="container">
              <div className="details-heading">
                <div className="d-flex flex-column">
                  <h4 className="title">{tour?.packageName}</h4>
                  <div className="d-flex flex-wrap align-items-center gap-30 mt-16">
                    <div className="location">
                      <i className="ri-map-pin-line"></i>
                      <div className="name">
                        {tour?.stateFrom}
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="d-flex align-items-center flex-wrap gap-20">
                      <div className="count">
                        <i className="ri-time-line"></i>
                        <p className="pera">
                          {tour?.days} Days {tour?.nights} Night
                        </p>
                      </div>
                      <div className="count">
                        <i className="ri-user-line"></i>
                        <p className="pera">2 Person</p>
                      </div>
                      <div className="price-review" onClick={scrollToReviews} style={{ cursor: "pointer" }}>
                        <div className="rating">
                          {/* Render the stars */}
                          {stars.map((star, index) => (
                            <span
                              key={index}
                              className={`star ${star}`}
                            >
                              &#9733; {/* Unicode character for a star */}
                            </span>
                          ))}
                          <p className="pera">
                            {tour?.reviewCount === 0 ? 0 : averageRating.toFixed(1)} ({tour?.reviewCount} Reviews)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-30">
                <div className="d-sm-block d-lg-none mb-30">
                  <TravelCard content={tour} />
                </div>
                <div className="row g-4">
                  <div className="col-xl-8 col-lg-7">
                    <TourContent content={tour} ref={reviewsRef} />
                  </div>

                  <div className="col-xl-4 col-lg-5 d-xs-none d-sm-none d-lg-block">
                    <TravelCard content={tour} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TourDetail;
