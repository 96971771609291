import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import Breadcrumbs from "../Common/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import Booking from "./Booking";
import UpdateProfile from "./UpdateProfile";
import ChangePassword from "./ChangePassword";

const TabButton = ({
  id,
  target,
  label,
  active,
  onClick,
  onLogout,
  iconClass,
}) => (
  <div
    className={`explore-btn ${active ? "active" : ""}`}
    id={id}
    data-bs-toggle="pill"
    data-bs-target={target}
    role="tab"
    aria-controls={target.slice(1)}
    aria-selected={active}
    onClick={() => {
      if (label === "Logout" && onLogout) {
        onLogout();
      } else if (onClick) {
        onClick();
      }
    }}
  >
    <div className="d-flex gap-16 align-items-center">
      <i className={iconClass}></i>
      <h4 className="name">{label}</h4>
    </div>
  </div>
);

const TabContent = ({ id, title, children, active }) => (
  <div
    className={`tab-pane fade ${active ? "show active" : ""}`}
    id={id}
    role="tabpanel"
    aria-labelledby={`${id}-tab`}
  >
    <div className="explore-content">
      <h4 className="title">{title}</h4>
      {children}
    </div>
  </div>
);

const Dashboard = ({ toggleTheme }) => {
  const [activeTab, setActiveTab] = useState("pills-explore-one");
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem("authToken");

    // Navigate to the home page
    navigate("/");
  };

  useEffect(() => {
    // Check if user is authenticated (you can use localStorage or other authentication state)
    const authToken = localStorage.getItem("authToken");

    if (!authToken) {
      // If authToken is not present, redirect to login
      return navigate("/Login");
    }
  }, [navigate]);

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="My Profile" link="/Dashboard" />

      <section className="explore-area section-padding2">
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-4 col-lg-5 col-md-6">
              <div
                className="all-explore"
                id="v-pills-tab-three"
                role="tablist"
                aria-orientation="vertical"
              >
                <TabButton
                  id="pills-explore-one-tab"
                  target="#pills-explore-one"
                  label="Dashboard"
                  active={activeTab === "pills-explore-one"}
                  onClick={() => setActiveTab("pills-explore-one")}
                  iconClass="ri-dashboard-line"
                />
                <TabButton
                  id="pills-explore-two-tab"
                  target="#pills-explore-two"
                  label="My Booking"
                  active={activeTab === "pills-explore-two"}
                  onClick={() => setActiveTab("pills-explore-two")}
                  iconClass="ri-book-line"
                />
                <TabButton
                  id="pills-explore-three-tab"
                  target="#pills-explore-three"
                  label="My Profile"
                  active={activeTab === "pills-explore-three"}
                  onClick={() => setActiveTab("pills-explore-three")}
                  iconClass="ri-user-line"
                />
                <TabButton
                  id="pills-explore-four-tab"
                  target="#pills-explore-four"
                  label="Change Password"
                  active={activeTab === "pills-explore-four"}
                  onClick={() => setActiveTab("pills-explore-four")}
                  iconClass="ri-lock-line"
                />
                <TabButton
                  id="pills-explore-five-tab"
                  target="#pills-explore-five"
                  label="Logout"
                  active={activeTab === "pills-explore-five"}
                  onClick={() => setActiveTab("pills-explore-five")}
                  onLogout={handleLogout}
                  iconClass="ri-logout-box-line"
                />
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-6">
              <div className="tab-content" id="v-pills-tabContent-three">
                <TabContent
                  id="pills-explore-one"
                  title="Dashboard"
                  active={activeTab === "pills-explore-one"}
                >
                  <p className="pera">
                    Lorem ipsum dolor sit amet consectetur. Nullam amet at sed
                    dui tellus tempor pretium tincidunt. Id amet sit viverra
                    dolor consectetur elementum. Non at volutpat aliquam ac ac
                    at amet. Ut semper semper sit aliquam penatibus dolor tortor
                    nisl.
                  </p>
                </TabContent>
                <TabContent
                  id="pills-explore-two"
                  title="My Booking"
                  active={activeTab === "pills-explore-two"}
                >
                  <Booking />
                </TabContent>
                <TabContent
                  id="pills-explore-three"
                  title="My Profile"
                  active={activeTab === "pills-explore-three"}
                >
                  <UpdateProfile />
                </TabContent>
                <TabContent
                  id="pills-explore-four"
                  title="Change Password"
                  active={activeTab === "pills-explore-four"}
                >
                  <ChangePassword />
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
