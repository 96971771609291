import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Breadcrumbs from "../../Common/Breadcrumbs";
import Footer from "../../Components/Footer/Footer";
import SpecialOffer from "../../Components/Home/SpecialOffer";
import AboutUs from "../../Components/Home/AboutUs";
import Brands from "./Brands";
import Testimonial from "./Testimonial";
import { useApi } from "../../Context/ApiProvider";

const About = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [about, setAbout] = useState(null);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    const fetchAbout = async () => {
      try {
        const response = await api.get(`CMS/ByCMSName?title=about`); // Update API URL

        setAbout(response.body.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching about:", error);
      }
    };

    const handleFetchData = () => {
      fetchAbout();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]); // include api if it is subject to change

  if (loading) {
    return <div className="loading">
      <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="About" link="/About" />
      <AboutUs content={about} />
      {/* <Brands /> */}
      <Testimonial />
      <SpecialOffer />
      <Footer />
    </div>
  );
};

export default About;
