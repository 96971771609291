import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Breadcrumbs from "../../Common/Breadcrumbs";
import logo from "../../Assets/images/logo/logo.png";

const NewPassword = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="New Password" link="/newPassword" />

      <div className="login-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10">
              <div className="login-card">
                <div className="logo mb-40">
                  <Link to="/" className="mb-30 d-block">
                    <img
                      src={logo}
                      alt="logo"
                      className="changeLogo"
                      style={{ width: "100px" }}
                    />
                  </Link>
                </div>
                <form action="#" method="POST">
                  <div className="position-relative contact-form mb-24">
                    <label className="contact-label">New Password</label>
                    <input
                      type="password"
                      className="form-control contact-input password-input"
                      id="txtPasswordLogin"
                      placeholder="Enter Your Password"
                    />
                    <i className="toggle-password ri-eye-off-line"></i>
                  </div>
                  <div className="position-relative contact-form mb-24">
                    <label className="contact-label">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control contact-input password-input"
                      id="txtPasswordLogin2"
                      placeholder="Enter Your Confirm Password"
                    />
                    <i className="toggle-password ri-eye-off-line"></i>
                  </div>
                  <Link
                    to="/"
                    className="btn-primary-fill justify-content-center w-100"
                  >
                    <span className="d-flex justify-content-center gap-6">
                      <span>Continue</span>
                    </span>
                  </Link>
                </form>

                <div className="login-footer">
                  <div className="create-account">
                    <p className="mb-0">
                      Go back to{" "}
                      <Link to="/Login">
                        <span className="text-primary">Login</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewPassword;
