import React, { useEffect } from "react";

const Package = ({ tours, handleTabClick, activeTab }) => {
  // Filter tours to exclude "All" (tourTypeId 0)
  const filteredTours = tours.filter((tour) => tour.tourTypeId !== 0);

  // Set initial active tab to the first tour in the filtered list if not already set
  useEffect(() => {
    if (!activeTab && filteredTours.length > 0) {
      handleTabClick(filteredTours[0].tourTypeId);
    }
  }, [activeTab, filteredTours, handleTabClick]);

  return (
    <section className="explore-area section-padding2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title text-center mx-430 mx-auto position-relative mb-60">
              <span className="highlights">Explore The World</span>
              <h4 className="title">Our Best Offer Package For You</h4>
            </div>
          </div>
        </div>
        <div className="row g-4">
          <div className="col-xl-5 col-lg-5 col-md-5">
            <div
              className="all-explore"
              id="v-pills-tab-three"
              role="tablist"
              aria-orientation="vertical"
            >
              {filteredTours.map((tour) => (
                <div
                  key={tour.tourTypeId}
                  className={`explore-btn ${activeTab === tour.tourTypeId ? "active" : ""
                    }`}
                  id={`pills-explore-${tour.tourTypeId}-tab`}
                  data-bs-toggle="pill"
                  data-bs-target={`#pills-explore-${tour.tourTypeId}`}
                  role="tab"
                  aria-controls={`pills-explore-${tour.tourTypeId}`}
                  aria-selected={activeTab === tour.tourTypeId}
                  onClick={() => handleTabClick(tour.tourTypeId)}
                >
                  <div className="d-flex gap-16 align-items-center">
                    <h4 className="name">{tour.tourTypeName}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-7">
            <div className="tab-content" id="v-pills-tabContent-three">
              {filteredTours.map((tour) => (
                <div
                  key={tour.tourTypeId}
                  className={`tab-pane fade ${activeTab === tour.tourTypeId ? "show active" : ""
                    }`}
                  id={`pills-explore-${tour.tourTypeId}`}
                  role="tabpanel"
                  aria-labelledby={`pills-explore-${tour.tourTypeId}-tab`}
                >
                  <div className="explore-content">
                    <h4 className="title">{tour.tourTypeName}</h4>
                    <div
                      className="pera"
                      dangerouslySetInnerHTML={{
                        __html: tour.tourTypeDetail,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Package;
