import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFilterCommon from "../../Hooks/useFilterCommon";
import { useApi } from "../../Context/ApiProvider";
import $ from "jquery";
import "select2/dist/js/select2.full.min.js";
import "select2/dist/css/select2.min.css";

const PlanArea = ({ tours }) => {
  const [destinations, setDestinations] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const api = useApi();
  const navigate = useNavigate();
  const destinationRef = useRef(null);
  const tourTypeRef = useRef(null);

  // Format date function
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Initialize date picker on mount
  useEffect(() => {
    const today = new Date();

    // Calculate date 3 months from today
    const threeMonthsLater = new Date();
    threeMonthsLater.setMonth(today.getMonth() + 3);

    // const tomorrow = new Date();
    // tomorrow.setDate(today.getDate() + 1);

    setStartDate(formatDate(today));
    setEndDate(formatDate(threeMonthsLater));
  }, []);

  const searchPlan = () => {
    const selectedTourType = $(tourTypeRef.current).val();
    const selectedCity = $(destinationRef.current).val();

    // Update the URL with the selected filters
    navigate(
      `/TourList?tourtype=${selectedTourType}&city=${selectedCity}&startDate=${startDate}&endDate=${endDate}`
    );
  };

  useEffect(() => {
    // Store references in local variables
    const destinationSelect = $(destinationRef.current);
    const tourTypeSelect = $(tourTypeRef.current);

    // Initialize Select2 for destination and tour type dropdowns
    destinationSelect.select2({
      containerCssClass: "custom-select2-dropdown",
      dropdownCssClass: "custom-select2-dropdown-container",
    });

    tourTypeSelect.select2({
      containerCssClass: "custom-select2-dropdown",
      dropdownCssClass: "custom-select2-dropdown-container",
    });

    // Clean up function
    return () => {
      // Destroy Select2 instances using local variables
      if (destinationSelect && destinationSelect.length) {
        destinationSelect.select2("destroy");
      }
      if (tourTypeSelect && tourTypeSelect.length) {
        tourTypeSelect.select2("destroy");
      }
    };
  }, []);

  const handleDateApply = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const { handleDateDropdownClick } = useFilterCommon(
    handleDateApply,
    startDate,
    endDate
  );

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await api.get("Data/GetDestination");
        setDestinations(response.body.data || []);
      } catch (err) {
        console.error("Error fetching destinations", err);
      }
    };

    const handleFetchData = () => {
      fetchDestinations();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]); // include api if it is subject to change

  return (
    <section className="plan-area">
      <div className="container">
        <div className="plan-section plan-shadow">
          <div className="select-dropdown-section">
            <div className="d-flex gap-10 align-items-center">
              <i className="ri-map-pin-line"></i>
              <h4 className="select2-title">Destination</h4>
            </div>
            <select ref={destinationRef} className="destination-dropdown">
              {destinations &&
                destinations.map((destination) => (
                  <option key={destination.cityId} value={destination.cityName}>
                    {destination.cityName}, {destination.stateName}
                  </option>
                ))}
            </select>
          </div>
          <div className="divider plan-divider d-none d-lg-block"></div>
          <div className="select-dropdown-section">
            <div className="d-flex gap-10 align-items-center">
              <i className="ri-flight-takeoff-fill"></i>
              <h4 className="select2-title">Tour Type</h4>
            </div>
            <select ref={tourTypeRef} className="destination-dropdown">
              {tours &&
                tours.map((tour) => (
                  <option key={tour.tourTypeId} value={tour.tourTypeName}>
                    {tour.tourTypeName}
                  </option>
                ))}
            </select>
          </div>
          <div className="divider plan-divider d-none d-lg-block"></div>
          <div className="dropdown-section">
            <div className="d-flex gap-10 align-items-center">
              <i className="ri-time-line"></i>
              <div
                className="custom-dropdown custom-date"
                onClick={handleDateDropdownClick}
              >
                <h4 className="title">Date From</h4>
                <div className="arrow">
                  <i className="ri-arrow-down-s-line"></i>
                </div>
              </div>
            </div>
            <input
              aria-label="date"
              readOnly
              className="date-result"
              type="text"
              name="daterange"
              value={`${startDate} - ${endDate}`}
            />
          </div>
          <div className="sign-btn">
            <button className="btn-secondary-lg" onClick={searchPlan}>
              Search Plan
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanArea;
