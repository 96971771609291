export default class ApiCall {
  constructor() {
    this.base_url = "https://api.routesandtours.com/api/";
    this.auth_url = "https://api.routesandtours.com/Users/token";
  }

  async request(options) {
    let response;
    let query = new URLSearchParams(options.query || {}).toString();

    let headers = {
      "Content-Type": "application/json",
      ...options.headers,
    };

    if (query !== "") {
      query = "?" + query;
      try {
        response = await fetch(this.base_url + options.url + query, {
          method: options.method,
          headers: headers,
          body: options.body ? JSON.stringify(options.body) : null,
        });
      } catch (error) {
        response = {
          ok: false,
          status: 500,
          json: async () => {
            return {
              code: 500,
              message: "The server is unresponsive",
              description: error.toString(),
            };
          },
        };
      }
    } else {
      query = options.body;
      try {
        if (options.headers !== undefined && options.headers !== "") {
          response = await fetch(this.base_url + options.url, {
            method: options.method,
            headers: headers,
            body: options.body ? JSON.stringify(options.body) : null,
          });
        } else {
          response = await fetch(this.base_url + options.url, {
            method: options.method,
            headers: {
              "Content-Type": "application/json",
            },
            body: options.body ? JSON.stringify(options.body) : null,
          });
        }
      } catch (error) {
        response = {
          ok: false,
          status: 500,
          json: async () => {
            return {
              code: 500,
              message: "The server is unresponsive",
              description: error.toString(),
            };
          },
        };
      }
    }

    return {
      ok: response.ok,
      status: response.status,
      body: response.status !== 204 ? await response.json() : null,
    };
  }

  async requestToken(options) {
    const response = await fetch(this.auth_url + options.body, {
      method: "POST",
      body: null,
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    return {
      status: response.status,
      result: result,
    };
  }

  async get(url, query, options) {
    return this.request({ method: "GET", url, query, ...options });
  }

  async post(url, body, options) {
    return this.request({ method: "POST", url, body, ...options });
  }

  async postToken(url, body, options) {
    return this.requestToken({ method: "POST", url, body, ...options });
  }
  async put(url, body, options) {
    return this.request({ method: "PUT", url, body, ...options });
  }

  async delete(url, query, options) {
    return this.request({ method: "DELETE", url, query, ...options });
  }
}
