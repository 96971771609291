import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useApi } from "../Context/ApiProvider";
import { useNavigate } from "react-router-dom";

const UpdateProfile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const token = localStorage.getItem("authToken");

        if (token) {
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await api.get("User/MyProfile", {}, config);
          if (response.body.success) {
            reset(response.body.data); // Set form values with the fetched data
          } else {
            // Handle error if needed
            console.error("Failed to fetch profile data");
          }
        }
      } catch (error) {
        localStorage.removeItem("authToken");
        navigate("/");
        console.error("An error occurred while fetching profile data:", error);
      }
    };

    const handleFetchData = () => {
      fetchProfileData();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api, reset, navigate]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError("");
    setSuccessMessage("");

    try {
      const response = await api.post("User/UpdateProfile", data);
      if (response.body.success) {
        // Handle successful profile update
        setSuccessMessage("Profile updated successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      } else {
        setError("Failed to update profile");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    } catch (error) {
      setError("An error occurred while updating profile:", error);
      setTimeout(() => {
        setError("");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row mt-4">
      {/* <div className="contact-form mb-20 col-lg-12">
        <label className="contact-label">Profile</label>
        <input className="form-control contact-input" type="file" />
      </div> */}
      <div className="contact-form mb-20 col-lg-6">
        <label className="contact-label">First Name </label>
        <input
          className="form-control contact-input"
          type="text"
          placeholder="First Name"
          {...register("firstName", {
            required: "First Name is required",
          })}
        />
        {errors.firstName && (
          <span className="text-red">{errors.firstName.message}</span>
        )}
      </div>
      <div className="contact-form mb-20 col-lg-6">
        <label className="contact-label">Last Name </label>
        <input
          className="form-control contact-input"
          type="text"
          placeholder="Last Name"
          {...register("lastName", {
            required: "Last Name is required",
          })}
        />
        {errors.lastName && (
          <span className="text-red">{errors.lastName.message}</span>
        )}
      </div>
      <div className="contact-form mb-20 col-lg-6">
        <label className="contact-label">Email </label>
        <input
          className="form-control contact-input"
          type="email"
          placeholder="Email"
          {...register("emailId", {
            required: "Email is required",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Invalid email address",
            },
          })}
        />
        {errors.emailId && (
          <span className="text-red">{errors.emailId.message}</span>
        )}
      </div>
      <div className="contact-form mb-20 col-lg-6">
        <label className="contact-label">Mobile Number </label>
        <input
          className="form-control contact-input"
          type="text"
          placeholder="Mobile Number"
          {...register("mobileNumber", {
            required: "Phone number is required",
            pattern: {
              value: /^[0-9]{10}$/,
              message: "Phone number must be 10 digits",
            },
          })}
        />
        {errors.mobileNumber && (
          <span className="text-red">{errors.mobileNumber.message}</span>
        )}
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <button
          type="submit"
          className="btn-primary-fill w-40"
          disabled={isLoading}
        >
          <span className="d-flex justify-content-center gap-6">
            <span>{isLoading ? "Updating..." : "Update Profile"}</span>
          </span>
        </button>
      </div>
      {error && <p className="error-message text-danger">{error}</p>}
      {successMessage && (
        <p className="success-message text-success">{successMessage}</p>
      )}
    </form>
  );
};

export default UpdateProfile;
