import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../Context/ApiProvider";
import { Image_URL } from "../../Common/BaseURL";

const SpecialOffer = () => {
  const [offers, setOffers] = useState([]);
  const api = useApi();

  useEffect(() => {
    const fetchOffersData = async () => {
      try {
        const response = await api.get(`Data/GetPackageExplore?etype=offer`); // Update API URLF
        setOffers(response.body.data);
      } catch (error) {
        console.error("Error fetching offer:", error);
      }
    }

    const handleFetchData = () => {
      fetchOffersData();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api])

  return (
    <section className="special-area section-padding2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title mx-430 mx-auto text-center">
              <span className="highlights fancy-font font-400">
                special offers
              </span>
              <h4 className="title">Winter Our Big Offers to Inspire You</h4>
            </div>
          </div>
        </div>
        <div className="row g-4">
          {offers && offers.map((offer, index) => (
            <div className="col-lg-6 col-md-6" key={index}>
              <Link
                to={`/TourDetail/${offer.packageName}`}
                className="offer-banner imgEffect4 wow fadeInLeft"
                data-wow-delay="0.0s"
              >
                <img src={
                  offer.imagename
                    ? `${Image_URL}/Package/${offer.imagename}`
                    : "assets/images/gallery/offercard-1.jpg"
                }
                  alt={offer.packageName} />
                <div className="offer-content">
                  {/* <p className="highlights-text">Save up to</p> */}
                  <h4 className="title">{offer.packageName}</h4>
                  {/* <p className="pera">Let’s Explore The World</p> */}
                  <div className="location">
                    <i className="ri-map-pin-line"></i>
                    <p className="name">{offer.cityname}, {offer.stateName}</p>
                  </div>
                  <div className="btn-secondary-sm radius-30"> Booking Now </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SpecialOffer;
