import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Breadcrumbs from "../../Common/Breadcrumbs";
import { useApi } from "../../Context/ApiProvider";
import { useForm } from "react-hook-form";
import logo from "../../Assets/images/logo/logo.png";

const ForgotPassword = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError(""); // Clear previous errors
    setSuccessMessage("");

    try {
      // Construct the query string from form data
      const queryString = `Username=${data.emailId}`;

      // Make the API request
      const response = await api.get(`User/ForgotPassword?${queryString}`);

      // Check if response contains the expected structure
      if (response.body && response.body.success === true) {
        setSuccessMessage("Reset Password link has sent to your email id!");
        reset();
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      } else {
        // Handle error based on response structure
        setError(
          response.body.message ||
          "Failed to send password reset instructions. Please try again"
        ); // Set specific error message or fallback
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    } catch (err) {
      setError("An error occurred. Please try again."); // Handle network or unexpected errors
      setTimeout(() => {
        setError("");
      }, 3000);
    } finally {
      setIsLoading(false); // Always stop loading spinner
    }
  };

  if (loading) {
    return <div className="loading">
      <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Forgot Password" link="/ForgotPassword" />

      <div className="login-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10">
              <div className="login-card">
                <div className="logo mb-40">
                  <Link to="/" className="mb-30 d-block">
                    <img
                      src={logo}
                      alt="logo"
                      className="changeLogo"
                      style={{ width: "100px" }}
                    />
                  </Link>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="contact-form mb-24">
                    <label className="contact-label">Email </label>
                    <input
                      className="form-control contact-input"
                      type="email"
                      placeholder="Email"
                      {...register("emailId", {
                        required: "Email is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.emailId && (
                      <span className="text-red">{errors.emailId.message}</span>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="btn-primary-fill justify-content-center w-100"
                    disabled={isLoading}
                  >
                    <span className="d-flex justify-content-center gap-6">
                      <span>
                        {isLoading ? " Resetting..." : "Reset Password"}
                      </span>
                    </span>
                  </button>

                  {error && (
                    <p className="error-message text-danger">{error}</p>
                  )}
                  {successMessage && (
                    <p className="success-message text-success">
                      {successMessage}
                    </p>
                  )}
                </form>

                <div className="login-footer">
                  <div className="create-account">
                    <p className="mb-0">
                      Go back to{" "}
                      <Link to="/Login">
                        <span className="text-primary">Login</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
