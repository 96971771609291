import React, { useState } from "react";
import { useApi } from "../../../Context/ApiProvider";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

const TravelCard = ({ content }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
    watch
  } = useForm();

  const { packageId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [thankYouModalOpen, setThankYouModalOpen] = useState(false);
  // const [formData, setFormData] = useState({
  //   noofKids: 0,
  // });
  const api = useApi();

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeReviewModal = () => {
    setReviewModalOpen(false);
  };

  const handleReviewModal = () => {
    setReviewModalOpen(true);
  };

  const handleCheckAvailability = () => {
    setModalOpen(true);
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleRatingChange = (star) => {
    setValue("rating", star); // Set rating value in react-hook-form
    clearErrors("rating"); // Clear validation error for rating
  };

  const handleReview = async (data) => {
    setIsLoading(true);
    setError(""); // Clear previous errors
    setSuccessMessage("");

    try {
      const requestBody = {
        ...data,
        packageId: content?.packageId,
        packageName: packageId,
      };

      const response = await api.post("Review/create", requestBody);

      if (response.body && response.body.success === true) {
        setSuccessMessage(
          response.body.message || "Review Added Successfully"
        );

        setTimeout(() => {
          closeReviewModal();
        }, 2000);

        setTimeout(() => {
          setSuccessMessage("");
        }, 1000);
        reset();
      } else {
        setError(
          response.body.message || "Failed to add Review. Please try again."
        ); // Handle specific error
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    } catch (error) {
      setError("An error occurred. Please try again."); // Handle unexpected errors
      setTimeout(() => {
        setError("");
      }, 3000);
    } finally {
      setIsLoading(false); // Stop the loader
    }
  };

  const handleCheckSubmit = async (data) => {
    setIsLoading(true);
    setError(""); // Clear previous errors
    setSuccessMessage("");

    try {
      const requestBody = {
        ...data,
        packageId: content?.packageId,
        packageName: packageId
      };

      const response = await api.post("inquiry/create", requestBody);

      // Check if response contains the expected structure
      if (response.body && response.body.success === true) {
        setSuccessMessage(
          response.body.message ||
          "Your inquiry is submitted and we will call back you shortly"
        );

        setTimeout(() => {
          closeModal();
        }, 2000);

        setTimeout(() => {
          setSuccessMessage("");
          setThankYouModalOpen(true);
        }, 1000);
        reset();
      } else {
        // Handle error based on response structure
        setError(
          response.body.message ||
          "Check Availability failed. Please try again."
        ); // Set specific error message or fallback
        setTimeout(() => {
          setError("");
        }, 3000);
      }
      // }
    } catch (error) {
      setError("An error occurred. Please try again."); // Handle network or unexpected errors
      setTimeout(() => {
        setError("");
      }, 3000);
    } finally {
      setIsLoading(false); // Always stop loading spinner
    }
  };

  return (
    <>
      <div className="date-travel-card position-sticky top-0">
        <div className="price-review">
          <div className="d-flex gap-10 align-items-end">
            <p className="light-pera">From</p>
            <p className="pera">₹{content?.priceForDouble}</p>
          </div>
          <div>Per person on twin sharing</div>
        </div>
        <div className="row mt-10 g-3">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <button
              type="button"
              className="send-btn w-100"
              onClick={handleCheckAvailability}
              style={{ padding: "10px 0", fontSize: "14px" }}
            >
              Submit Query
            </button>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <button
              type="button"
              className="send-btn w-100"
              onClick={handleReviewModal}
              style={{ padding: "10px 0", fontSize: "14px" }}
            >
              Review
            </button>
          </div>
        </div>
      </div>

      {/* Check Availability Modal */}
      {modalOpen && (
        <div className="modal fade modal-overlay-check row">
          <div className="modal-dialog" role="document">
            <div className="modal-content check">
              <div className="modal-header">
                <h5 className="modal-title">Submit Query</h5>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={handleSubmit(handleCheckSubmit)}
                  className="row"
                >
                  <div className="position-relative contact-form mb-10 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label className="contact-label">Name </label>
                    <input
                      className="form-control contact-input"
                      type="text"
                      placeholder="Name"
                      {...register("name", {
                        required: "Required",
                      })}
                    />
                    {errors.name && (
                      <span className="text-red">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label className="contact-label">Email </label>
                    <input
                      className="form-control contact-input"
                      type="email"
                      placeholder="Email"
                      {...register("email", {
                        required: "Required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="text-red">{errors.email.message}</span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label className="contact-label">Phone </label>
                    <input
                      className="form-control contact-input"
                      type="number"
                      placeholder="Phone"
                      {...register("phone", {
                        required: "Required",
                        minLength: {
                          value: 10,
                          message: "Phone number should be 10 digits",
                        },
                        maxLength: {
                          value: 10,
                          message: "Phone number should be 10 digits",
                        },
                      })}
                    />
                    {errors.phone && (
                      <span className="text-red">{errors.phone.message}</span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label className="contact-label">Destination </label>
                    <input
                      className="form-control contact-input"
                      type="text"
                      placeholder="Destination"
                      {...register("destination", {
                        required: "Required",
                      })}
                      defaultValue={content?.stateFrom}
                    />
                    {errors.destination && (
                      <span className="text-red">
                        {errors.destination.message}
                      </span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label className="contact-label">Departure City </label>
                    <input
                      className="form-control contact-input"
                      type="text"
                      placeholder="Departure City"
                      {...register("departureCity", {
                        required: "Required",
                      })}
                    />
                    {errors.departureCity && (
                      <span className="text-red">
                        {errors.departureCity.message}
                      </span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label className="contact-label">Travel Date </label>
                    <input
                      className="form-control contact-input"
                      type="date"
                      placeholder="Travel Date"
                      {...register("travelDate", {
                        required: "Required",
                      })}
                    />
                    {errors.travelDate && (
                      <span className="text-red">
                        {errors.travelDate.message}
                      </span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <label className="contact-label">No of Days </label>
                    <input
                      className="form-control contact-input"
                      type="number"
                      placeholder="Number of Days"
                      {...register("noofDays", {
                        required: "Required",
                        min: {
                          value: 1,
                          message: "Should be at least 1",
                        },
                      })}
                    />
                    {errors.noofDays && (
                      <span className="text-red">
                        {errors.noofDays.message}
                      </span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <label className="contact-label">No of Adults </label>
                    <input
                      className="form-control contact-input"
                      type="number"
                      placeholder="Number of Adults"
                      {...register("noofAdult", {
                        required: "Required",
                        min: {
                          value: 1,
                          message: "Should be at least 1",
                        },
                      })}
                    />
                    {errors.noofAdult && (
                      <span className="text-red">
                        {errors.noofAdult.message}
                      </span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <label className="contact-label">No of Kids </label>
                    <input
                      className="form-control contact-input"
                      type="number"
                      placeholder="Number of Kids"
                      {...register("noofKids", {
                        required: false,
                      })}
                    />
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label className="contact-label">Comments </label>
                    <textarea
                      className="form-control contact-input"
                      placeholder="Additional Comments"
                    // {...register("comment", {
                    //   required: "Required",
                    // })}
                    />
                    {/* {errors.comment && (
                      <span className="text-red">{errors.comment.message}</span>
                    )} */}
                  </div>
                </form>
              </div>
              <div className="modal-footer gap-3">
                <button
                  type="button"
                  className="send-btn-check"
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className={`send-btn-check ${isLoading ? 'cursor-not-allowed' : ''} ${isLoading ? 'disabled' : ''}`}
                  onClick={handleSubmit(handleCheckSubmit)}
                  disabled={isLoading} // This disables the button when isLoading is true
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
              {error && <p className="error-message text-danger">{error}</p>}
              {successMessage && (
                <p className="success-message text-success">{successMessage}</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Review Modal */}
      {reviewModalOpen && (
        <div className="modal fade modal-overlay-check row">
          <div className="modal-dialog" role="document">
            <div className="modal-content check">
              <div className="modal-header">
                <h5 className="modal-title">Submit Review</h5>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={handleSubmit(handleReview)}
                  className="row"
                >
                  <div className="position-relative contact-form mb-10 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label className="contact-label">Name </label>
                    <input
                      className="form-control contact-input"
                      type="text"
                      placeholder="Name"
                      {...register("name", {
                        required: "Required",
                      })}
                    />
                    {errors.name && (
                      <span className="text-red">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label className="contact-label">Email </label>
                    <input
                      className="form-control contact-input"
                      type="email"
                      placeholder="Email"
                      {...register("email", {
                        required: "Required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="text-red">{errors.email.message}</span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label className="contact-label">Phone </label>
                    <input
                      className="form-control contact-input"
                      type="number"
                      placeholder="Phone"
                      {...register("phone", {
                        required: "Required",
                        minLength: {
                          value: 10,
                          message: "Phone number should be 10 digits",
                        },
                        maxLength: {
                          value: 10,
                          message: "Phone number should be 10 digits",
                        },
                      })}
                    />
                    {errors.phone && (
                      <span className="text-red">{errors.phone.message}</span>
                    )}
                  </div>
                  <div className="rating-stars position-relative contact-form mb-10 col-lg-12">
                    <label>Rating</label>
                    <br />
                    <div className="d-flex">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <span
                          key={star}
                          className={`star ${star <=
                            (errors.rating ? 0 : watch("rating"))
                            ? "filled"
                            : ""
                            }`}
                          onClick={() => handleRatingChange(star)}
                          {...register("rating", {
                            required: "Required",
                          })}
                        >
                          ★
                        </span>
                      ))}
                    </div>
                    {errors.rating && (
                      <span className="text-red">
                        {errors.rating.message}
                      </span>
                    )}
                  </div>
                  <div className="position-relative contact-form mb-10 col-lg-12">
                    <label className="contact-label">Comments </label>
                    <textarea
                      className="form-control contact-input"
                      placeholder="Additional Comments"
                      {...register("comment", {
                        required: "Required",
                      })}
                    />
                    {errors.comment && (
                      <span className="text-red">
                        {errors.comment.message}
                      </span>
                    )}
                  </div>
                </form>
              </div>
              <div className="modal-footer gap-3">
                <button
                  type="button"
                  className="send-btn-check"
                  onClick={closeReviewModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className={`send-btn-check ${isLoading ? 'cursor-not-allowed' : ''} ${isLoading ? 'disabled' : ''}`}
                  onClick={handleSubmit(handleReview)}
                  disabled={isLoading}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
              {error && <p className="error-message text-danger">{error}</p>}
              {successMessage && (
                <p className="success-message text-success">{successMessage}</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Thankyou Modal */}
      {thankYouModalOpen && (
        <div className="modal fade modal-overlay-check row">
          <div className="modal-dialog" role="document">
            <div className="modal-content thankyou">
              <div className="modal-header">
                <h5 className="modal-title">Thank you for reaching out!</h5>
              </div>
              <div className="modal-body">
                <p className="mb-10">We've received your inquiry, and our team is excited to help you plan your travel adventure. One of our travel specialists will get back to you shortly with all the information you need. In the meantime, feel free to explore our site for more travel inspiration!</p>

                <div>
                  <strong>Safe travels, </strong>
                </div>
                <div>
                  <strong>The Routes and Tours Team</strong>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="send-btn-check"
                  onClick={() => setThankYouModalOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TravelCard;
