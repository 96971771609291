import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useApi } from "../Context/ApiProvider";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError("");
    setSuccessMessage("");

    try {
      const token = localStorage.getItem("authToken");

      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await api.post("User/ChangePassword", data, config);
        if (response.body.success) {
          // Handle successful password update
          setSuccessMessage("Password updated successfully");
          reset();

          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
        } else {
          // Handle error based on response structure
          setError(
            response.body.message ||
              "Failed to update password. Please try again."
          ); // Set specific error message or fallback
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      }
    } catch (error) {
      setError("An error occurred while updating password:", error);
      setTimeout(() => {
        setError("");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row mt-4">
      <div className="contact-form mb-20 col-lg-12">
        <div className="position-relative ">
          <div className="d-flex justify-content-between align-items-center">
            <label className="contact-label">Current Password</label>
          </div>
          <input
            type="password"
            className="form-control contact-input password-input"
            id="txtCPasswordLogin"
            placeholder="Enter Current Password"
            {...register("oldPassword", {
              required: "Current Password is required",
            })}
          />
          <i className="toggle-password ri-eye-off-line"></i>
          {errors.oldPassword && (
            <span className="text-red">{errors.oldPassword.message}</span>
          )}
        </div>
      </div>
      <div className="contact-form mb-20 col-lg-6">
        <div className="position-relative ">
          <div className="d-flex justify-content-between align-items-center">
            <label className="contact-label">New Password</label>
          </div>
          <input
            type="password"
            className="form-control contact-input password-input"
            id="txtNPasswordLogin"
            placeholder="Enter New Password"
            {...register("newPassword", {
              required: "New Password is required",
            })}
          />
          <i className="toggle-password ri-eye-off-line"></i>
          {errors.newPassword && (
            <span className="text-red">{errors.newPassword.message}</span>
          )}
        </div>
      </div>
      <div className="contact-form mb-20 col-lg-6">
        <div className="position-relative ">
          <div className="d-flex justify-content-between align-items-center">
            <label className="contact-label">Confirm Password</label>
          </div>
          <input
            type="password"
            className="form-control contact-input password-input"
            id="txtCoPasswordLogin"
            placeholder="Enter Confirm Password"
            {...register("newPasswordRetype", {
              required: "Confirm Password is required",
            })}
          />
          <i className="toggle-password ri-eye-off-line"></i>
          {errors.newPasswordRetype && (
            <span className="text-red">{errors.newPasswordRetype.message}</span>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <button
          type="submit"
          className="btn-primary-fill w-40"
          disabled={isLoading}
        >
          <span className="d-flex justify-content-center gap-6">
            <span>{isLoading ? "Updating..." : "Update Password"}</span>
          </span>
        </button>
      </div>
      {error && <p className="error-message text-danger">{error}</p>}
      {successMessage && (
        <p className="success-message text-success">{successMessage}</p>
      )}
    </form>
  );
};

export default ChangePassword;
