import React from "react";

const FaqDetail = ({ content }) => {
  return (
    <div className="faqs-area bottom-padding">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="single-policy mb-30">
              <div
                className="pera"
                dangerouslySetInnerHTML={{ __html: content?.contents }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqDetail;
