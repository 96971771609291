import React, { useEffect, useRef } from "react";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Breadcrumbs from "../../Common/Breadcrumbs";
import logo from "../../Assets/images/logo/logo.png";

const Verification = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  const handleInputChange = (e) => {
    if (e.target.value.length === 1) {
      switch (e.target.name) {
        case "input1":
          input2Ref.current.focus();
          break;
        case "input2":
          input3Ref.current.focus();
          break;
        case "input3":
          input4Ref.current.focus();
          break;
        default:
          break;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      switch (e.target.name) {
        case "input2":
          if (e.target.value === "") {
            input1Ref.current.focus();
          }
          break;
        case "input3":
          if (e.target.value === "") {
            input2Ref.current.focus();
          }
          break;
        case "input4":
          if (e.target.value === "") {
            input3Ref.current.focus();
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Verification" link="/Verification" />

      <div className="login-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10">
              <div className="login-card">
                <div className="logo mb-40">
                  <Link to="/" className="mb-30 d-block">
                    <img
                      src={logo}
                      alt="logo"
                      className="changeLogo"
                      style={{ width: "100px" }}
                    />
                  </Link>
                </div>
                <form action="#" method="POST">
                  <div className="contact-form">
                    <label className="contact-label">Email </label>
                  </div>

                  <div className="row">
                    <div className="col-3 col-lg-3 col-md-3">
                      <div className="contact-form mb-24">
                        <input
                          className="form-control contact-input text-center"
                          type="text"
                          name="input1"
                          maxLength="1"
                          ref={input1Ref}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          placeholder="5"
                        />
                      </div>
                    </div>
                    <div className="col-3 col-lg-3 col-md-3">
                      <div className="contact-form mb-24">
                        <input
                          className="form-control contact-input text-center"
                          type="text"
                          name="input2"
                          maxLength="1"
                          ref={input2Ref}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div className="col-3 col-lg-3 col-md-3">
                      <div className="contact-form mb-24">
                        <input
                          className="form-control contact-input text-center"
                          type="text"
                          name="input3"
                          maxLength="1"
                          ref={input3Ref}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          placeholder="3"
                        />
                      </div>
                    </div>
                    <div className="col-3 col-lg-3 col-md-3">
                      <div className="contact-form mb-24">
                        <input
                          className="form-control contact-input text-center"
                          type="text"
                          name="input4"
                          maxLength="1"
                          ref={input4Ref}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          placeholder="1"
                        />
                      </div>
                    </div>
                  </div>

                  <Link
                    to="/newPassword"
                    className="btn-primary-fill justify-content-center w-100"
                  >
                    <span className="d-flex justify-content-center gap-6">
                      <i className="ri-lock-line"></i>
                      <span>Verify</span>
                    </span>
                  </Link>
                </form>

                <div className="login-footer">
                  <div className="create-account">
                    <p className="mb-0">
                      Go back to{" "}
                      <Link to="/Login">
                        <span className="text-primary">Login</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Verification;
