import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Breadcrumbs from "../../Common/Breadcrumbs";
import Footer from "../../Components/Footer/Footer";
import FaqQuestion from "./FaqQuestion";
import FaqDetail from "./FaqDetail";
import { useApi } from "../../Context/ApiProvider";

const FAQ = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [faq, setFaq] = useState(null);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    const fetchFaq = async () => {
      try {
        const response = await api.get(`CMS/ByCMSName?title=faq`); // Update API URL

        setFaq(response.body.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching faq:", error);
      }
    };

    const handleFetchData = () => {
      fetchFaq();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]); // include api if it is subject to change

  if (loading) {
    return <div className="loading">
      <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="FAQ" link="/faq" />
      <FaqQuestion content={faq} />
      <FaqDetail content={faq} />
      <Footer />
    </div>
  );
};

export default FAQ;
