import { useEffect } from "react";
import $ from "jquery";
import "daterangepicker/daterangepicker.css";
import "daterangepicker";

const useFilterCommon = (onDateApply, defaultStartDate, defaultEndDate) => {
  const handleDateDropdownClick = () => {
    $('input[name="daterange"]').data("daterangepicker").show();
  };

  useEffect(() => {
    // Initialize DateRangePicker with default start and end dates
    const datepicker = $('input[name="daterange"]').daterangepicker(
      {
        opens: "left",
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        // minDate: defaultStartDate,
        minDate: new Date(),
        locale: {
          format: "DD/MM/YYYY", // Ensure format is correct
        },
      },
      function (start, end) {
        onDateApply(start.format("DD/MM/YYYY"), end.format("DD/MM/YYYY"));
      }
    );

    return () => {
      if (datepicker) {
        datepicker.data("daterangepicker").remove();
      }
    };
  }, [defaultStartDate, defaultEndDate, onDateApply]);

  return {
    handleDateDropdownClick,
  };
};

export default useFilterCommon;
