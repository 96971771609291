import React, { forwardRef, useState } from "react";
import include from "../../../Assets/images/icon/checkmark.png";
import exclude from "../../../Assets/images/icon/closemark.png";
import face from "../../../Assets/images/icon/default-face.png";
import { Image_URL } from "../../../Common/BaseURL";
import TravelCard from "./TravelCard";

const TourContent = forwardRef(({ content }, ref) => {
  const [contentModalOpen, setContentModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageList, setImageList] = useState([]);
  const [reviewsToShow, setReviewsToShow] = useState(5);

  // Function to handle image click and trigger modal
  const handleImageClick = (src, type, images, baseUrl) => {
    setImageList(images.map(image => `${baseUrl}/${image.fileName}`)); // Construct the full URL
    setCurrentImageIndex(images.findIndex(image => image.fileName === src.split('/').pop())); // Set the current index
    setContentModalOpen(true);
  };

  // Function to navigate to the next image
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
  };

  // Function to navigate to the previous image
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imageList.length) % imageList.length);
  };

  // Function to handle click outside the modal content
  const handleOutsideClick = (event) => {
    if (event.target.className.includes("modal-overlay-check")) {
      setContentModalOpen(false);
    }
  };

  const formatReviewDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    // Extract day and add suffix (e.g., "1st", "2nd", "3rd", "4th", etc.)
    const day = date.getDate();
    const daySuffix = getDaySuffix(day);

    // Reassemble the date in "Month Dayth, Year" format
    return formattedDate.replace(day, `${day}${daySuffix}`);
  };

  // Function to get the day suffix (e.g., "st", "nd", "rd", "th")
  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  // Handler for "See More" button click
  const handleSeeMore = () => {
    setReviewsToShow(prev => prev + 5); // Load 5 more reviews
  };

  return (
    <>
      <div>
        <div className="tour-details-content">
          <h4 className="title">About</h4>
          <div
            className="pera"
            dangerouslySetInnerHTML={{ __html: content?.packageDetail }}
          />
        </div>

        <div className="tour-details-content">
          <h4 className="title">Highlight</h4>
          <div
            className="pera-high"
            dangerouslySetInnerHTML={{ __html: content?.packageHighLight }}
          />
        </div>

        {content?.includeExclude.length > 0 && (
          <div className="tour-include-exclude radius-6">
            <div className="includ-exclude-point">
              <h4 className="title">Included</h4>
              <ul className="expect-list">
                {content?.includeExclude
                  .filter((item) => item.include)
                  .map((item, index) => (
                    <li key={index} className="list">
                      <img
                        src={include}
                        alt="included"
                        width={15}
                        height={15}
                        style={{ marginRight: "8px" }}
                      />{" "}
                      {item.facility}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="divider"></div>
            <div className="includ-exclude-point">
              <h4 className="title">Exclude</h4>
              <ul className="expect-list">
                {content?.includeExclude
                  .filter((item) => !item.include)
                  .map((item, index) => (
                    <li key={index} className="list">
                      <img
                        src={exclude}
                        alt="excluded"
                        width={15}
                        height={15}
                        style={{ marginRight: "8px" }}
                      />{" "}
                      {item.facility}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}

        {content?.planDetail.length > 0 && (
          <div className="tour-details-content mb-30">
            <h4 className="title">Tour Plan</h4>
            <div className="destination-accordion">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                {content?.planDetail.map((plan, index) => {
                  const headingId = `panelsStayOpen-heading${index}`;
                  const collapseId = `panelsStayOpen-collapse${index}`;

                  return (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" id={headingId}>
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${collapseId}`}
                          aria-expanded={index === 0 ? "true" : "false"}
                          aria-controls={collapseId}
                        >
                          Day {plan?.days} - {plan?.planTitle}
                        </button>
                      </h2>
                      <div
                        id={collapseId}
                        className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                          }`}
                        aria-labelledby={headingId}
                      >
                        <div className="accordion-body">
                          {plan?.destinations?.length > 0 && (
                            <div className="city-list">
                              <h6>Cities:</h6>
                              {plan?.destinations?.map((city, i) => (
                                <div className="city-item" key={i}>
                                  <span className="city-icon"></span>
                                  <span className="city-name">{city.city}</span>
                                </div>
                              ))}
                            </div>
                          )}

                          <div
                            dangerouslySetInnerHTML={{
                              __html: plan?.dayPlanDetail,
                            }}
                          />

                          <div className="mt-15">
                            {plan?.hotels?.length > 0 && (
                              <div className="city-list">
                                <h6>Hotel:</h6>
                                {plan?.hotels?.map((hotel, i) => (
                                  <div className="city-item" key={i}>
                                    <span className="city-icon"></span>
                                    <span className="city-name">{hotel.hotelName}</span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          <div className="mt-15">
                            {plan?.roomCategory?.length > 0 && (
                              <div className="city-list">
                                <h6>Room Category:</h6>
                                <div className="city-item">
                                  <span className="city-icon"></span>
                                  <span className="city-name">{plan?.roomCategory}</span>
                                </div>
                              </div>
                            )}
                          </div>

                          {plan?.hotelFiles?.length > 0 && (
                            <div className="row">
                              {plan?.hotelFiles.map((file, idx) => {
                                const fileUrl = `${Image_URL}/hotel/${file.fileName}`;
                                const fileExtension = file.fileName.split('.').pop().toLowerCase();

                                return (
                                  <div className="col-lg-4 col-md-4 col-sm-6 mb-3 image-item" key={index} onClick={() => handleImageClick(fileUrl, fileExtension, plan?.hotelFiles, `${Image_URL}/hotel`)}>
                                    {fileExtension === 'mp4' || fileExtension === 'webm' ? (
                                      <video src={fileUrl} controls />
                                    ) : (
                                      <img src={fileUrl} alt={file.fileName} />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          {plan?.destinationFiles?.length > 0 && (
                            <div className="mt-15 row">
                              <h6 className="mb-15">Sightseeing:</h6>
                              {plan?.destinationFiles.map((file, index) => {
                                const fileUrl = `${Image_URL}/packageplan/${file.fileName}`;
                                const fileExtension = file.fileName.split('.').pop().toLowerCase();

                                return (
                                  <div className="col-lg-4 col-md-4 col-sm-6 mb-3 image-item" key={index} onClick={() => handleImageClick(fileUrl, fileExtension, plan?.destinationFiles, `${Image_URL}/packageplan`)}>
                                    {fileExtension === 'mp4' || fileExtension === 'webm' ? (
                                      <video src={fileUrl} controls />
                                    ) : (
                                      <img src={fileUrl} alt={file.fileName} />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <div className="tour-details-content">
          <h4 className="title">Policy</h4>
          <div
            className="pera"
            dangerouslySetInnerHTML={{ __html: content?.packagePolicy }}
          />
        </div>

        <div className="col-xl-4 col-lg-5 d-xs-block d-sm-block d-lg-none mb-20">
          <TravelCard content={content} />
        </div>

        <div ref={ref}>
          <div className="tour-details-content">
            <h4 className="title">Reviews</h4>
            <div>
              {content?.reviews?.slice(0, reviewsToShow).map((review, index) => (
                <div key={index} className="review-block">
                  <div className="flex justify-content-between mb-10">
                    <div className="flex">
                      <div className="customer-profile">
                        <img src={review?.customerProfileImage || face} alt="profile" style={{ width: "50px" }} />
                      </div>
                      <div style={{ marginLeft: "15px" }}>
                        <div className="customer-name">{review?.customerName}</div>
                        {/* Display Rating as Stars */}
                        <div className="rating">
                          {[...Array(5)].map((_, i) => (
                            <span key={i} className={i < review?.rating ? 'filled' : 'empty'}>
                              {i < review?.rating ? '★' : '☆'}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <p className="review-date">
                      {formatReviewDate(review?.reviewDate)}
                    </p>
                  </div>
                  <p className="pera">{review?.comment}</p>
                </div>
              ))}

              {/* Display the "See More" button if there are more reviews to load */}
              {content?.reviews?.length > reviewsToShow && (
                <div className="d-flex justify-content-center align-items-center">
                  <button onClick={handleSeeMore} className="send-btn">
                    See More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap Modal */}
      {contentModalOpen && (
        <div className="modal fade modal-overlay-check row" onClick={handleOutsideClick}>
          <div className="modal-dialog" role="document">
            <div className="modal-content check">
              <div className="modal-header">
                <h5 className="modal-title">Image Preview</h5>
              </div>
              <div className="modal-body">
                {imageList.length > 0 && (
                  <>
                    {currentImageIndex > 0 && (
                      <button className="btn btn-primary left-prev" onClick={handlePrevImage}><i className="ri-arrow-left-s-line" /></button>
                    )}
                    {currentImageIndex < imageList.length - 1 && (
                      <button className="btn btn-primary left-next" onClick={handleNextImage}><i className="ri-arrow-right-s-line" /></button>
                    )}
                    {imageList[currentImageIndex].endsWith('.mp4') || imageList[currentImageIndex].endsWith('.webm') ? (
                      <video src={imageList[currentImageIndex]} controls style={{ width: "100%", height: "284px" }} />
                    ) : (
                      <img src={imageList[currentImageIndex]} alt="Preview" style={{ width: "100%", height: "284px" }} />
                    )}
                  </>
                )}
              </div>
              <div className="modal-footer gap-3">
                <button
                  type="button"
                  className="send-btn-check"
                  onClick={() => setContentModalOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default TourContent;
