import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import Breadcrumbs from "../../Common/Breadcrumbs";
import Footer from "../../Components/Footer/Footer";
import TourIncludeExclude from "./TourIncludeExclude";
import TravelCard from "../Tour/TourDetail/TravelCard";

const Payment = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Payment" link="/Payment" />

      <section className="tour-details-section section-padding2">
        <div className="tour-details-area">
          <div className="tour-details-container">
            <div className="container">
              <div className="details-heading mb-30">
                <div className="d-flex flex-column">
                  <h4 className="title">
                    Travello Tour - Best of Samyan Bangkok
                  </h4>
                  <div className="d-flex flex-wrap align-items-center gap-30 mt-16">
                    <div className="location">
                      <i className="ri-map-pin-line"></i>
                      <div className="name">Bangkok, Thailand</div>
                    </div>
                    <div className="divider"></div>
                    <div className="d-flex align-items-center flex-wrap gap-20">
                      <div className="count">
                        <i className="ri-time-line"></i>
                        <p className="pera">3 Days 2 Night</p>
                      </div>
                      <div className="count">
                        <i className="ri-user-line"></i>
                        <p className="pera">2 Person</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="price-review">
                  <div className="d-flex gap-10 align-items-end">
                    <p className="light-pera">From</p>
                    <p className="pera">₹95</p>
                  </div>
                  <div className="rating">
                    <i className="ri-star-s-fill"></i>
                    <p className="pera">4.7 (20 Reviews)</p>
                  </div>
                </div>
              </div>
              <div className="row g-4">
                <div className="col-xl-8">
                  <TourIncludeExclude />
                </div>
                <div className="col-xl-4">
                  <TravelCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Payment;
