import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import logo from "../../Assets/images/logo/logo.png";
import face from "../../Assets/images/icon/face.png";
import banner from "../../Assets/images/hero/testimonial-three-banner.png";
import shape from "../../Assets/images/icon/feature-shape.png";
import graphic from "../../Assets/images/icon/graphic.png";
import { useApi } from "../../Context/ApiProvider";

const Testimonial = () => {
  const [testimonial, setTestimonial] = useState([]);
  const api = useApi();

  useEffect(() => {
    const fetchTestimonialData = async () => {
      try {
        const response = await api.get(`Review/GetReviewList`); // Update API URL
        setTestimonial(response.body.data);
      } catch (error) {
        console.error("Error fetching feature:", error);
      }
    };

    const handleFetchData = () => {
      fetchTestimonialData();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]);

  return (
    <section className="testimonial-area-three position-relative section-bg-before-two top-padding">
      <div className="container">
        <div className="row justify-content-center position-relative">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title mx-430 mx-auto text-center">
              <span className="highlights fancy-font font-400">
                Testimonial
              </span>
              <h4 className="title">What People Have Said About Our Service</h4>
            </div>
          </div>
        </div>
        <div className="row g-4 align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="swiper testimonialThree-active">
              <Swiper
                loop={true}
                autoplay={{
                  delay: 3000,
                }}
                pagination={{
                  el: ".swiper-pagination",
                  type: "progressbar",
                }}
                modules={[Navigation, Autoplay, Pagination]}
              >
                {testimonial.map((item) => (
                  <SwiperSlide
                    key={item.packageId}
                    className="testimonial-card"
                  >
                    <div className="testimonial-header">
                      <div className="user-img">
                        <img src={face} alt="User" />
                      </div>
                      <div className="user-info">
                        <p className="name">{item.customerName}</p>
                        <p className="designation">Traveler</p>
                      </div>
                    </div>
                    <div className="rattings">
                      {[...Array(5)].map((_, index) => (
                        <i
                          key={index}
                          className={
                            index < item.rating
                              ? "ri-star-fill"
                              : "ri-star-line"
                          }
                        ></i>
                      ))}
                    </div>
                    <div className="testimonial-body">
                      <p className="pera line-clamp-3">{item.comment}</p>
                    </div>
                    <div className="testimonial-footer">
                      <div className="logo">
                        <img src={logo} alt="Routes and Tours" className="changeLogo" />
                      </div>
                      <p className="date">
                        {new Date(item.reviewDate).toLocaleDateString("en-US", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-pagination"></div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="hero-banner imgEffect2 o-hidden radius-20">
              <img src={banner} alt="Routes and Tours" />
              <div className="shape">
                <img src={shape} alt="Routes and Tours" />
              </div>
              <Link
                to="https://www.youtube.com/watch?v=Cn4G2lZ_g2I"
                data-fancybox="video-gallery"
                className="wow bounceIn"
                data-wow-delay=".2s"
              >
                <div className="video-player">
                  <i className="ri-play-fill"></i>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-testimonial">
        <img src={graphic} alt="Routes and Tours" />
      </div>
    </section>
  );
};

export default Testimonial;
