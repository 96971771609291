import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/images/logo/logo.png";

const HeaderTop = ({ toggleTheme, toggleMobileMenu }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const token = localStorage.getItem("authToken");
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isBackdropVisible, setBackdropVisible] = useState(false);
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/TourList?search=${searchQuery}&page=1`);
    setSearchOpen(false);
  };

  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
    setBackdropVisible(!isSearchOpen); // Show/hide backdrop
  };

  const handleMouseEnter = () => setIsDropdownOpen(true);
  const handleMouseLeave = () => setIsDropdownOpen(false);

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem("authToken");

    // Navigate to the home page
    navigate("/");
  };

  // Use useCallback to memoize the handler function
  const handleClickOutside = useCallback(
    (event) => {
      if (
        isSearchOpen &&
        !event.target.closest(".mobile-search-input") &&
        !event.target.closest(".search-bar")
      ) {
        setSearchOpen(false);
        setBackdropVisible(false); // Hide backdrop
      }
    },
    [isSearchOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]); // Add handleClickOutside as a dependency

  return (
    <div className="header-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="top-menu-wrapper d-flex align-items-center justify-content-between">
              <div className="top-header-right">
                <div className="contact-section">
                  <div className="circle-primary-sm">
                    <i className="ri-phone-line"></i>
                  </div>
                  <div className="info">
                    <p className="pera">Call Anytime</p>
                    <h4 className="title">
                      <Link to="/">(+91) 901 590 1277</Link>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="logo">
                <Link to="/">
                  <img
                    src={logo}
                    alt="logo"
                    className="changeLogo"
                    style={{ width: "100px" }}
                  />
                </Link>
              </div>
              <div className="header-right-three pl-15 d-none d-lg-flex">
                <div className="d-flex gap-20 align-items-center">
                  {!token ? (
                    <>
                      <p className="pera">
                        <Link to="/Login" className="signin">
                          Sign In
                        </Link>
                      </p>
                      <div className="sign-btn">
                        <Link
                          to="/Register"
                          className="btn-secondary-sm radius-30"
                        >
                          Sign Up
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div
                      className="dropdown-container"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <div className="sign-btn">
                        <Link to="" className="btn-secondary-sm radius-30">
                          <i className="ri-user-2-line"></i> Profile
                        </Link>
                      </div>
                      {isDropdownOpen && (
                        <div className="dropdown-menu">
                          <Link to="/Dashboard" className="dropdown-item">
                            Dashboard
                          </Link>
                          <Link
                            to="/"
                            className="dropdown-item"
                            onClick={handleLogout}
                          >
                            Logout
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                  <button
                    className="ToggleThemeButton change-theme-mode m-0 p-0 border-0"
                    aria-label="ToggleThemeButton"
                    onClick={toggleTheme}
                  >
                    <i className="ri-sun-line"></i>
                  </button>
                </div>
              </div>
              <div className="d-block d-lg-none">
                <div className="d-flex gap-15">
                  <div className="search-bar">
                    <button
                      className="rounded-btn"
                      aria-label="search"
                      onClick={toggleSearch}
                    >
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                  <button
                    className="ToggleThemeButton change-theme-mode m-0 p-0 border-0"
                    aria-label="ToggleThemeButton"
                    onClick={toggleTheme}
                  >
                    <i className="ri-sun-line"></i>
                  </button>
                  {/* Mobile Menu Toggle */}
                  <button
                    className="mobile-menu-toggle"
                    onClick={toggleMobileMenu}
                    aria-label="Toggle mobile menu"
                  >
                    <i className="ri-menu-3-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {isSearchOpen && (
            <>
              <div className="mobile-search-input d-block d-lg-none">
                <form onSubmit={handleSearchSubmit}>
                  <div className="header-search">
                    <input
                      type="text"
                      className="search-input-mobile"
                      placeholder="Destination, attraction"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </form>
              </div>
              {isBackdropVisible && <div className="backdrop"></div>}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
