import React, { useEffect, useState } from "react";
import { useApi } from "../Context/ApiProvider";

const Booking = () => {
  const [bookingList, setBookingList] = useState([]);
  const api = useApi();

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const token = localStorage.getItem("authToken");

        if (token) {
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await api.get(`Inquiry/UserAllInquiry`, {}, config); // Update API URL

          setBookingList(response.body.data);
        }
      } catch (error) {
        console.error("Error fetching faq:", error);
      }
    };

    const handleFetchData = () => {
      fetchBooking();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]); // include api if it is subject to change

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Returns the date in YYYY-MM-DD format
  };

  return (
    <div className="destination-details-info mt-10">
      <div className="info-table">
        <table className="table">
          <thead>
            <tr>
              <th>Package</th>
              <th>Travel Date</th>
              <th>Days</th>
              <th>Adults</th>
              <th>Kids</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {bookingList?.map((book, index) => (
              <tr key={index}>
                <td>{book.packageId}</td>
                <td>{formatDate(book.travelDate)}</td>
                <td>{book.noofDays}</td>
                <td>{book.noofAdult}</td>
                <td>{book.noofKids}</td>
                <td>{book.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Booking;
