import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Breadcrumbs from "../../Common/Breadcrumbs";
import Footer from "../../Components/Footer/Footer";
import { useApi } from "../../Context/ApiProvider";

const Privacy = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [privacy, setPrivacy] = useState(null);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    const fetchPrivacy = async () => {
      try {
        const response = await api.get(`CMS/ByCMSName?title=privacy`); // Update API URL

        setPrivacy(response.body.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching privacy policy:", error);
      }
    };

    const handleFetchData = () => {
      fetchPrivacy();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]); // include api if it is subject to change

  if (loading) {
    return <div className="loading">
      <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Privacy Policy" link="/Privacy" />

      <div className="privacy-policy-area section-padding2">
        <div className="container">
          <h2 className="title mb-20 font-700 text-center">{privacy?.title}</h2>
          <div className="row">
            <div className="col-xl-12">
              <div className="single-policy mb-30">
                <div
                  className="pera"
                  dangerouslySetInnerHTML={{ __html: privacy?.contents }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;
