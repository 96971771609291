import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../Context/ApiProvider";
import logo from "../../Assets/images/logo/logo.png";

const FooterMiddleArea = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const api = useApi();

  // Regex pattern for validating email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!emailRegex.test(email.trim())) {
      setError("Please enter a valid email address");
      return;
    }

    try {
      const response = await api.post(`Subscriber/Create`, {
        email: email,
      });
      if (response.ok) {
        setSuccessMessage("Thank you for subscribing to our newsletter!");
        setEmail("");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      } else {
        setError(response.body.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    } catch (error) {
      setError(error.message);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <div>
      <div className="footer-body">
        <div className="footer-content">
          <div className="d-flex flex-column gap-20">
            <div className="logo">
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  className="changeLogo"
                  style={{ width: "100px" }}
                />
              </Link>
            </div>
            <p className="pera">
              Travel is a transformative and enriching experience that allows
              individuals to explore new destinations, cultures, and landscapes.
            </p>
          </div>
          <div className="footer-right">
            <h4 className="title">Subscribe Our Newsletter</h4>
            <div className="subscribe-wraper">
              <form className="newsletter-inner" id="mc-form">
                <input
                  className="footer-search"
                  type="search"
                  name="footer"
                  id="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Enter Your Email"
                />
                <button className="subscribe-btn" onClick={handleSubmit}>
                  Subscribe
                </button>
              </form>
              {/* Display success message */}
              {successMessage && (
                <p style={{ marginTop: "10px", color: "green" }}>
                  {successMessage}
                </p>
              )}
              {/* Display error message */}
              {error && (
                <p style={{ marginTop: "10px", color: "red" }}>{error}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <ul className="listing">
          <li className="single-list">
            <Link to="/TermsCondition" className="single">
              Terms of use
            </Link>
          </li>
          <li className="single-list">
            <Link to="/Privacy" className="single">
              Privacy and Cookies Statement
            </Link>
          </li>
          <li className="single-list">
            <Link to="/Contact" className="single">
              How the site works
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterMiddleArea;
