import React, { useEffect, useState } from "react";
import { useApi } from "../../Context/ApiProvider";

const FaqQuestion = ({ content }) => {
  const [faqList, setFaqList] = useState([]);
  const api = useApi();

  useEffect(() => {
    const fetchFaq = async () => {
      try {
        const response = await api.get(`faq/GetFaqList`); // Update API URL

        setFaqList(response.body.data);
      } catch (error) {
        console.error("Error fetching faq:", error);
      }
    };

    const handleFetchData = () => {
      fetchFaq();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]); // include api if it is subject to change

  return (
    <section className="question-area section-padding">
      <div className="container">
        <h2 className="title mb-20 font-700 text-center">{content?.title}</h2>
        <div className="row align-items-center">
          <div className="col-lg-8">
            <div className="section-tittle mb-50">
              <h2 className="title font-700">Any Questions</h2>
              <p className="pera">
                When deciding which charity to donate to, it's important to do
                your search and find one that aligns with your values and
                interests.
              </p>
            </div>
            <div className="accordion" id="accordionExample">
              {faqList.map((faq, index) => (
                <div className="accordion-item" key={faq.faqId}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className="accordion-body"
                      dangerouslySetInnerHTML={{ __html: faq.solution }}
                    ></div>

                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <img
              className="w-100 d-none d-lg-block tilt-effect radius-10"
              src="assets/images/gallery/faq.jpg"
              alt="faq"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqQuestion;
