import React, { useEffect, useState } from "react";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import HeaderTop from "./HeaderTop";

const Header = ({ toggleTheme }) => {
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const token = localStorage.getItem("authToken");
  const location = useLocation();
  const navigate = useNavigate();
  const matchTourDetail = useMatch("/TourDetail/:packageId");
  const matchDestinationDetail = useMatch("/DestinationDetail/:destinationType/:destinationName");
  // State to manage submenu visibility
  const [isSubmenuVisible, setSubmenuVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/TourList?search=${searchQuery}&page=1`);
  };

  // Toggle submenu visibility
  const toggleSubmenu = () => {
    setSubmenuVisible(!isSubmenuVisible);
  };

  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const closeSearch = () => {
    setSearchOpen(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      closeSearch();
    }
    if ((event.ctrlKey || event.metaKey) && event.key === "k") {
      toggleSearch();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  useEffect(() => {
    if (isSearchOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isSearchOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isSearchOpen &&
        !event.target.closest(".search-container") &&
        !event.target.closest(".search-bar")
      ) {
        closeSearch();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSearchOpen]);

  const handleLogout = (e) => {
    // Remove the token from localStorage
    localStorage.removeItem("authToken");

    // Navigate to the home page
    navigate("/");
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header-area-three">
      <div className="main-header">
        <HeaderTop
          toggleTheme={toggleTheme}
          toggleMobileMenu={toggleMobileMenu}
        />
        <div className="header-bottom header-sticky">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="menu-wrapper">
                  <div className="main-menu d-none d-lg-block">
                    <nav>
                      <div className="d-flex justify-content-between align-items-center">
                        <ul className="listing" id="navigation">
                          <li className="single-list">
                            <Link
                              to="/"
                              className={
                                location.pathname === "/"
                                  ? "single link-active"
                                  : "single"
                              }
                            >
                              Home
                            </Link>
                          </li>
                          <li className="single-list">
                            <Link
                              to="/About"
                              className={
                                location.pathname === "/About"
                                  ? "single link-active"
                                  : "single"
                              }
                            >
                              About
                            </Link>
                          </li>
                          <li className="single-list">
                            <Link
                              to="/DestinationList"
                              className={
                                location.pathname === "/DestinationList" ||
                                  matchDestinationDetail
                                  ? "single link-active"
                                  : "single"
                              }
                            >
                              Destination
                            </Link>
                          </li>
                          <li className="single-list">
                            <Link
                              to="/TourList"
                              className={
                                location.pathname === "/TourList" ||
                                  matchTourDetail
                                  ? "single link-active"
                                  : "single"
                              }
                            >
                              Holiday Packages
                            </Link>
                          </li>
                          <li className="single-list">
                            <Link
                              to="/Contact"
                              className={
                                location.pathname === "/Contact"
                                  ? "single link-active"
                                  : "single"
                              }
                            >
                              Contact
                            </Link>
                          </li>
                        </ul>
                        <div className="search-box search-bar d-none d-lg-block">
                          <form onSubmit={handleSearchSubmit}>
                            <div className="header-search">
                              <input
                                type="text"
                                className="search-input"
                                placeholder="Destination, attraction"
                                value={searchQuery}
                                onChange={handleSearchChange}
                              />
                              <div className="search-icon">
                                <i className="ri-search-line"></i>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                {isMobileMenuOpen && (
                  <div className="div">
                    <div className="mobile_menu d-block d-lg-none">
                      <div className="slicknav_menu">
                        <ul className="listing slicknav_nav">
                          <li className="single-list">
                            <Link
                              to="/"
                              className={
                                location.pathname === "/"
                                  ? "single link-active"
                                  : "single"
                              }
                            >
                              Home
                            </Link>
                          </li>
                          <li className="single-list">
                            <Link
                              to="/About"
                              className={
                                location.pathname === "/About"
                                  ? "single link-active"
                                  : "single"
                              }
                            >
                              About
                            </Link>
                          </li>
                          <li className="single-list">
                            <Link
                              to="/DestinationList"
                              className={
                                location.pathname === "/DestinationList" ||
                                  matchDestinationDetail
                                  ? "single link-active"
                                  : "single"
                              }
                            >
                              Destination
                            </Link>
                          </li>
                          <li className="single-list">
                            <Link
                              to="/TourList"
                              className={
                                location.pathname === "/TourList" ||
                                  matchTourDetail
                                  ? "single link-active"
                                  : "single"
                              }
                            >
                              Holiday Packages
                            </Link>
                          </li>
                          <li className="single-list">
                            <Link
                              to="/Contact"
                              className={
                                location.pathname === "/Contact"
                                  ? "single link-active"
                                  : "single"
                              }
                            >
                              Contact
                            </Link>
                          </li>
                          {!token ? (
                            <li className="d-block d-lg-none">
                              <div className="header-right-three pl-15 mt-10">
                                <div className="sign-btn">
                                  <Link
                                    to="/Login"
                                    className="btn-secondary-sm m-0"
                                  >
                                    Sign In
                                  </Link>
                                </div>
                              </div>
                            </li>
                          ) : (
                            <li className="single-list">
                              <Link
                                to="#"
                                className="single"
                                onClick={toggleSubmenu}
                              >
                                User Profile
                                <span className="slicknav_arrow">
                                  {isSubmenuVisible ? "-" : "+"}
                                </span>
                              </Link>
                              {isSubmenuVisible && (
                                <ul className="submenu">
                                  <li className="single-list">
                                    <Link to="/Dashboard" className="single">
                                      Dashboard
                                    </Link>
                                  </li>
                                  <li
                                    className="single-list"
                                    onClick={handleLogout}
                                  >
                                    <Link to="/" className="single">
                                      Logout
                                    </Link>
                                  </li>
                                </ul>
                              )}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
